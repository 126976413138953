import * as React from "react"
import cx from "classnames"

import Link from "@components/elements/link"
import { ConditionalWrapper } from "@lib/utils"

import styles from "./cta.module.scss"

export interface CtaProps {
  className?: string
  url?: string
  forceInternal?: boolean
  onClick?: (event: React.MouseEvent) => void
  text?: string
  textMobile?: string
  type?: "submit" | "button"
  variant?: "primary" | "secondary"
  isSmall?: boolean
  isDisabled?: boolean
}

export const CtaContainer = (props: CtaProps) => {
  return (
    <div className={cx(styles.ctaContainer, props.className)}>
      <Cta {...props} className={""} />
    </div>
  )
}

const Cta = ({
  className,
  url,
  forceInternal,
  onClick,
  text,
  textMobile,
  type = "button",
  variant = "primary",
  isSmall = false,
  isDisabled = false,
}: CtaProps) => {
  if (!text) {
    return null
  }

  const classNames = cx(styles.cta, styles[variant], className, {
    [styles.isDisabled]: isDisabled,
    [styles.isSmall]: isSmall,
  })

  return (
    <ConditionalWrapper
      condition={!!url}
      wrapper={wrapChildren => {
        return (
          <Link url={url} forceInternal={forceInternal} className={classNames}>
            {wrapChildren}
          </Link>
        )
      }}
      elseWrapper={wrapChildren => (
        <button
          className={classNames}
          type={type}
          {...(onClick && {
            onClick: e => onClick(e),
          })}
        >
          {wrapChildren}
        </button>
      )}
    >
      <div className={cx(styles.corner, styles.topLeft)}></div>
      <div className={cx(styles.corner, styles.topRight)}></div>
      {textMobile && (
        <span className={cx(styles.text, styles.isMobile)}>{textMobile}</span>
      )}
      <span className={styles.text}>{text}</span>
      <div className={cx(styles.corner, styles.bottomLeft)}></div>
      <div className={cx(styles.corner, styles.bottomRight)}></div>
    </ConditionalWrapper>
  )
}

export default Cta
